$(document).ready(function(){

	var body = $('body');

	body.on('click', '#toggle-menu', function(e){
		e.preventDefault();

		body.toggleClass('menu-in');
	});

	$(document).click(function(){
	  $(".dropdown").removeClass('in');
	});

	$('header nav').on('click', '.dropdown > a', function(e){
		e.preventDefault();
		e.stopPropagation();

		var parent = $(this).closest('.dropdown');

		$(".dropdown").not(parent).removeClass('in');
		parent.toggleClass('in');
	});

	// Main Slider
	var mainSlideEnabled=$('section.slider li').length>1;

	$('section.slider ul').bxSlider({
		auto: mainSlideEnabled,
		touchEnabled: mainSlideEnabled,
    adaptiveHeight: true,
    pager: false,
    controls: mainSlideEnabled,
    nextText: '<svg xmlns="http://www.w3.org/2000/svg" width="29.2px" height="54.3px" viewBox="0 0 29.2 54.3"><polyline class="st0" points="0.7,53.6 27.8,26.5 2,0.7 "/></svg> ',
    prevText: '<svg xmlns="http://www.w3.org/2000/svg" width="29.2px" height="54.3px" viewBox="0 0 29.2 54.3"><polyline class="st0" points="28.5,0.7 1.4,27.8 27.2,53.6 "/></svg> ',
    pause: 7000,
    easing: 'ease-in-out',
    onSliderLoad: function(){
      $('section.slider ul').addClass("in");
    }
	});

	// Modal
	var modals = $('.modal');

	modals.on('show.bs.modal', function(e){
		const player = new Plyr($(e.currentTarget).find('.player'));

		player.play();

		$(e.currentTarget).on('hidden.bs.modal', function(e){
			player.destroy();
		})
	});
});
